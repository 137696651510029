<template>

  <!-- Dialog Add Staff And Payroll -->
  <div>
    <ib-dialog
      id="dialog-story-mode-add-staff"
      empty-header
      :visible="dialogAddStaffVisible"
      fullscreen
      @open="openDialog"
      @close="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2>
            {{ $tc('addPayrollExpense') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
        <!-- Header -->
        <add-dialog-header>
          <template #left>
            <!-- Title -->
            <h2 class="m-0">
              {{ $tc('addPayrollExpense') }}
            </h2>
            <!-- /Title -->
          </template>
        </add-dialog-header>
        <!-- /Header -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.staffAndPayroll.addDialog.guide.title')"
          :text="$t('pages.businessGuide.staffAndPayroll.addDialog.guide.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Ai Buddy -->
        <ai-buddy
          type="staff-and-payroll"
          class="ai-buddy-margin-bottom d-block d-xl-none"
          @suggestion="form.name = $event"
        />
        <!-- Ai Buddy -->

        <!-- Inner Wrapper -->
        <div class="inner-wrapper">
          <div class="grid-wrapper">
            <el-row :gutter="30">

              <!-- Left Side -->
              <el-col :md="12" :xl="7">

                <!-- Form Wrapper -->
                <div class="staff-form-wrapper">

                  <!-- Form Inner Top-->
                  <div class="staff-form-inner-top">

                    <!-- Form Top-->
                    <el-form ref="ruleFormTop" :model="formTop">

                      <!-- Employee Name Individual -->
                      <ib-input
                        v-model="form.name"
                        :label="$t('fields.name')"
                        :maxlength="50"
                      />
                      <!-- /Employee Name Individual -->

                      <!-- Employee Type-->
                      <el-form-item
                        class="input-inside-label" :label="$t('pages.businessGuide.staffAndPayroll.addDialog.fields.selectTypeOfEmployee')"
                        :class="{'focused' : focus.type}"
                      >

                        <!-- Employee Type Select -->
                        <ib-select
                          ref="groupSelect"
                          v-model="form.type"
                          :popper-append-to-body="false"
                          popper-class="promo-dropdown"
                          placeholder=" "
                          filterable=""
                          @focus="toggleFocus('type')"
                          @blur="toggleFocus('type')"
                          @change="onChangeType()"
                        >

                          <!-- Employee Type Groups -->
                          <div v-for="group in groups" :key="group.value" class="group-wrapper">

                            <!-- Employee Type Group -->
                            <div class="group-item-wrapper" :class="{'selected' : group.selected}">

                              <!-- Employee Type Group Image -->
                              <img :src="group.imgPath" svg-inline>
                              <!-- /Employee Type Group Image -->

                              <!-- Employee Type Group Label -->
                              <span>{{ group.label }}</span>
                              <!-- /Employee Type Group Label -->

                            </div>
                            <!-- /Employee Type Group -->

                            <!-- Employee Type Group Items -->
                            <ib-option
                              v-for="item in group.options"
                              :key="item.value"
                              :label="item.value"
                              :value="item.diffValue"
                              @click="selectGroup(group)"
                            >

                              <!-- Employee Type Group Items Label -->
                              <p class="staff-title">
                                {{ item.label }}
                              </p>
                              <!-- /Employee Type Group Items Label -->

                              <!-- Employee Type Group Items Description -->
                              <p class="staff-desc">
                                {{ item.desc }}
                              </p>
                              <!--/ Employee Type Group Items Description -->

                            </ib-option>
                            <!-- /Employee Type Group Items -->

                          </div>
                          <!-- /Employee Type Groups -->

                        </ib-select>
                        <!-- /Employee Type Select -->

                      </el-form-item>
                      <!-- /Employee Type -->

                    </el-form>
                    <!-- /Form Top -->

                  </div>
                  <!-- /Form Inner Top -->

                  <!-- Form Inner Bottom Individual -->
                  <div class="staff-form-inner-bottom">

                    <!-- Form Bottom Individual -->
                    <el-form v-if="form.type === 'fti' || form.type === 'ci'" ref="form" :model="form">

                      <!--                      &lt;!&ndash; Employee Name Individual &ndash;&gt;-->
                      <!--                      <ib-input-->
                      <!--                        v-model="form.name"-->
                      <!--                        :label="$t('fields.name')"-->
                      <!--                        :maxlength="50"-->
                      <!--                      />-->
                      <!--                      &lt;!&ndash; /Employee Name Individual &ndash;&gt;-->

                      <!-- Employee Salary And Interval Type Individual -->
                      <div class="group">

                        <!-- Employee Salary Individual -->
                        <el-form-item
                          class="input-inside-label"
                          :label="`${$t('pages.businessGuide.staffAndPayroll.addDialog.fields.salary')} (${currencySymbol})`"
                          :class="{'focused': focus.salary}"
                        >

                          <!-- Employee Salary Individual Input -->
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.salary"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('salary')"
                              @blur="toggleFocus('salary')"
                            />
                          </div>
                          <!-- /Employee Salary Individual Input -->

                        </el-form-item>
                        <!-- /Employee Salary Individual -->

                        <!-- Employee Interval Type Individual -->
                        <el-form-item
                          class="input-inside-label" label=""
                          :class="{'focused' : focus.salaryIntervalType}"
                        >

                          <!-- Employee Interval Type Individual Select-->
                          <ib-select
                            v-model="form.salaryIntervalType"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            :placeholder="$t('monthly')"
                            @focus="toggleFocus('salaryIntervalType')"
                            @blur="toggleFocus('salaryIntervalType')"
                            @change="changeSalaryIntervalType"
                          >
                            <ib-option :label="$t('monthly')" value="Monthly" />
                            <ib-option :label="$t('quarterly')" value="Quarterly" />
                            <ib-option :label="$t('annually')" value="Annually" />
                          </ib-select>
                          <!-- /Employee Interval Type Individual Select-->

                        </el-form-item>
                        <!-- /Employee Interval Type Individual -->

                      </div>
                      <!-- /Employee Salary And Interval Type Individual -->

                      <!-- Employee Pay Adjustment, Pay Adjustment Interval Type And Sign Value Switch Button Individual -->
                      <div class="group switches">

                        <!-- Employee Pay Adjustment Individual -->
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.staffAndPayroll.addDialog.fields.payAdjustment')"
                          :class="{'focused': focus.payAdjustmentAmount}"
                        >
                          <!-- Employee Pay Adjustment Individual Input -->
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.payAdjustmentAmount"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: form.isPayPercentage ? 100 : form.payAdjustmentPrefix === '-' && form.payAdjustmentAmount > form.salary ? form.salary : 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('payAdjustmentAmount')"
                              @blur="toggleFocus('payAdjustmentAmount')"
                            />
                          </div>
                          <!-- /Employee Pay Adjustment Individual Input -->

                          <!-- Employee Sign Value Switch Button Individual -->
                          <ib-tooltip
                            popper-class="center"
                            :content="$t('tooltipPositiveNegative')"
                          >
                            <el-button @click="toggleValue">
                              <i :class="signValue" class="el-icon" />
                            </el-button>
                          </ib-tooltip>
                          <!-- /Employee Sign Value Switch Button Individual -->

                        </el-form-item>
                        <!-- /Employee Pay Adjustment Individual -->

                        <!-- Employee Pay Adjustment Interval Type And Toggle Pay Percentage Individual -->
                        <el-form-item
                          class="input-inside-label" label=""
                          :class="{'focused' : focus.payAdjustmentIntervalType}"
                        >

                          <!-- Employee Pay Adjustment Interval Type Individual Select -->
                          <ib-select
                            v-model="form.payAdjustmentIntervalType"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            :placeholder="$t('monthly')"
                            @focus="toggleFocus('payAdjustmentIntervalType')"
                            @blur="toggleFocus('payAdjustmentIntervalType')"
                            @change="changePayAdjustmentIntervalType"
                          >
                            <ib-option :label="$t('monthly')" value="Monthly" />
                            <ib-option :label="$t('quarterly')" value="Quarterly" />
                            <ib-option :label="$t('annually')" value="Annually" />
                          </ib-select>
                          <!-- /Employee Pay Adjustment Interval Type Individual Select -->

                          <!-- Employee Toggle Pay Percentage Individual Button -->
                          <ib-tooltip
                            popper-class="center"
                            :content="$t('tooltipValuesPercentages')"
                          >
                            <el-button @click="togglePayment">
                              <i v-if="form.isPayPercentage" class="el-icon">%</i>
                              <i v-else
                                 class="currency-symbol"
                                 :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                              >
                                {{ currencySymbol }}
                              </i>
                            </el-button>
                          </ib-tooltip>
                          <!-- /Employee Toggle Pay Percentage Individual Button -->

                        </el-form-item>
                        <!-- /Employee Pay Adjustment Interval Type And Toggle Pay Percentage Individual -->

                      </div>
                      <!-- /Employee Pay Adjustment, Pay Adjustment Interval Type And Sign Value Switch Button Individual -->

                      <!-- Employee Salary Starts And Salary Ends Individual -->
                      <div class="group date">

                        <!-- Employee Salary Starts Individual -->
                        <el-form-item
                          class="input-inside-label yellow" :label="$t('starts')" prop="salaryStarts"
                          :class="{'focused': focus.salaryStarts}"
                        >

                          <!-- Employee Salary Starts Individual Date Picker -->
                          <el-date-picker
                            v-model="form.salaryStarts"
                            popper-class="date-picker-monthly-dropdown-modal"
                            :popper-append-to-body="false"
                            :picker-options="pickerOptionsStarts"
                            class="date-picker-monthly"
                            type="month"
                            :clearable="false"
                            format="MMM, yyyy"
                            value-format="yyyy-MM-dd"
                            @focus="toggleFocus('salaryStarts')"
                            @blur="toggleFocus('salaryStarts')"
                            @change="toggleFocus('salaryStarts')"
                          />
                          <!-- /Employee Salary Starts Individual Date Picker -->

                        </el-form-item>
                        <!-- /Employee Salary Starts Individual -->

                        <!-- Employee Salary Ends Individual -->
                        <el-form-item
                          class="input-inside-label yellow"
                          :label="$t('ends')"
                          prop="salaryEnds"
                          :class="{'focused': focus.salaryEnds}"
                        >

                          <!-- Employee Salary Ends Individual Date Picker -->
                          <el-date-picker
                            v-model="form.salaryEnds"
                            :clearable="false"
                            :picker-options="pickerOptionsEnds"
                            :popper-append-to-body="false"
                            format="MMM, yyyy"
                            value-format="yyyy-MM-dd"
                            type="month"
                            class="date-picker-monthly"
                            popper-class="date-picker-monthly-dropdown-modal ends"
                            @focus="toggleFocus('salaryEnds')"
                            @blur="toggleFocus('salaryEnds')"
                            @change="toggleFocus('salaryEnds')"
                          />
                          <!-- /Employee Salary Ends Individual Date Picker -->

                        </el-form-item>
                        <!-- /Employee Salary Ends Individual -->

                      </div>
                      <!-- /Employee Salary Starts And Salary Ends Individual -->

                    </el-form>
                    <!-- /Form Bottom Individual -->

                  </div>
                  <!-- /Form Inner Bottom Individual -->

                  <!-- Form Inner Bottom Group -->
                  <div class="staff-form-inner-bottom">

                    <!-- Form Bottom Group -->
                    <el-form v-if="form.type === 'ftg' || form.type === 'cg'" ref="form" :model="form">

                      <!-- Employee Name & How Many Group -->
                      <div class="employee-how-many">

                        <!-- Employee Name Group -->
                        <!--                        <el-form-item-->
                        <!--                          class="input-inside-label" :label="$t('fields.name')"-->
                        <!--                          :class="{'focused': focus.name}"-->
                        <!--                        >-->

                        <!--                          &lt;!&ndash; Employee Name Group Input&ndash;&gt;-->
                        <!--                          <el-input-->
                        <!--                            v-model="form.name"-->
                        <!--                            placeholder=" "-->
                        <!--                            class="input-with-select select-unfocused"-->
                        <!--                            @focus="toggleFocus('name')"-->
                        <!--                            @blur="toggleFocus('name')"-->
                        <!--                          />-->
                        <!--                          &lt;!&ndash; /Employee Name Group Input&ndash;&gt;-->

                        <!--                        </el-form-item>-->
                        <!-- /Employee Name Group -->

                        <!-- Employee How Many Group -->
                        <el-form-item
                          class="input-inside-label" label=""
                          :class="{'focused' : focus.howMany}"
                        >

                          <!-- Employee How Many Group Input -->
                          <ib-select
                            v-model="form.howMany"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            :placeholder="$t('pages.businessGuide.staffAndPayroll.addDialog.fields.howMany')"
                            @focus="toggleFocus('howMany')"
                            @blur="toggleFocus('howMany')"
                            @change="toggleFocus('howMany')"
                          >
                            <ib-option
                              v-for="number in 50" v-show="number > 1" :key="number" :label="number"
                              :value="number"
                            />
                          </ib-select>
                          <!-- /Employee How Many Group Input -->

                        </el-form-item>
                        <!-- /Employee How Many Group -->

                      </div>
                      <!-- /Employee Name & How Many Group -->

                      <!-- Employee Salary Per Employee & Salary Interval Type Group -->
                      <div class="group-multiple">

                        <!-- Employee Salary Per Employee Group -->
                        <el-form-item
                          class="input-inside-label"
                          :label="`${$t('pages.businessGuide.staffAndPayroll.addDialog.fields.salaryPerEmployee')} (${currencySymbol})`"
                          :class="{'focused': focus.salary}"
                        >

                          <!-- Employee Salary Per Employee Group Input -->
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.salary"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('salary')"
                              @blur="toggleFocus('salary')"
                            />
                          </div>
                          <!-- /Employee Salary Per Employee Group Input -->

                        </el-form-item>
                        <!-- /Employee Salary Per Employee Group -->

                        <!-- Employee Salary Interval Type Group -->
                        <el-form-item
                          class="input-inside-label" label=""
                          :class="{'focused' : focus.salaryIntervalType}"
                        >

                          <!-- Employee Salary Interval Type Group Select -->
                          <ib-select
                            v-model="form.salaryIntervalType"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            :placeholder="$t('monthly')"
                            @focus="toggleFocus('salaryIntervalType')"
                            @blur="toggleFocus('salaryIntervalType')"
                            @change="changeSalaryIntervalType"
                          >
                            <ib-option :label="$t('monthly')" value="Monthly" />
                            <ib-option :label="$t('quarterly')" value="Quarterly" />
                            <ib-option :label="$t('annually')" value="Annually" />
                          </ib-select>
                          <!-- /Employee Salary Interval Type Group Select -->

                        </el-form-item>
                        <!-- /Employee Salary Interval Type Group -->

                      </div>
                      <!-- /Employee Salary Per Employee & Salary Interval Type Group -->

                      <!-- Employee Pay Adjustment Amount, Pay Adjustment Interval Type, Sign Value & Pay Percentage Group -->
                      <div class="group-multiple switches">

                        <!-- Employee Pay Adjustment Amount Group -->
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.staffAndPayroll.addDialog.fields.payAdjustment')"
                          :class="{'focused': focus.payAdjustmentAmount}"
                        >

                          <!-- Employee Pay Adjustment Amount Group Input -->
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.payAdjustmentAmount"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: form.isPayPercentage ? 100 : form.payAdjustmentPrefix === '-' && form.payAdjustmentAmount > form.salary ? form.salary : 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('payAdjustmentAmount')"
                              @blur="toggleFocus('payAdjustmentAmount')"
                            />
                          </div>
                          <!-- /Employee Pay Adjustment Amount Group Input -->

                          <!-- Employee Sign Value Group Button -->
                          <ib-tooltip
                            popper-class="center"
                            :content="$t('tooltipPositiveNegative')"
                          >
                            <el-button @click="toggleValue">
                              <i :class="signValue" class="el-icon" />
                            </el-button>
                          </ib-tooltip>
                          <!-- /Employee Sign Value Group Button -->

                        </el-form-item>
                        <!--/ Employee Pay Adjustment Amount Group -->

                        <!-- Employee Pay Adjustment Interval Type Group -->
                        <el-form-item
                          class="input-inside-label" label=""
                          :class="{'focused' : focus.payAdjustmentIntervalType}"
                        >

                          <!-- Employee Pay Adjustment Interval Type Group Select -->
                          <ib-select
                            v-model="form.payAdjustmentIntervalType"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            :placeholder="$t('monthly')"
                            @focus="toggleFocus('payAdjustmentIntervalType')"
                            @blur="toggleFocus('payAdjustmentIntervalType')"
                            @change="changePayAdjustmentIntervalType"
                          >
                            <ib-option :label="$t('monthly')" value="Monthly" />
                            <ib-option :label="$t('quarterly')" value="Quarterly" />
                            <ib-option :label="$t('annually')" value="Annually" />
                          </ib-select>
                          <!-- /Employee Pay Adjustment Interval Type Group Select -->

                          <!-- Employee Pay Adjustment Percentage Toggle Group Button -->
                          <ib-tooltip
                            popper-class="center"
                            :content="$t('tooltipValuesPercentages')"
                          >
                            <el-button @click="togglePayment">
                              <i v-if="form.isPayPercentage" class="el-icon">%</i>
                              <i v-else class="currency-symbol">{{ currencySymbol }}</i>
                            </el-button>
                          </ib-tooltip>
                          <!-- /Employee Pay Adjustment Percentage Toggle Group Button -->

                        </el-form-item>
                        <!-- /Employee Pay Adjustment Interval Type Group -->

                      </div>
                      <!-- /Employee Pay Adjustment Amount, Pay Adjustment Interval Type, Sign Value & Pay Percentage Group -->

                      <!-- Employee Salary Starts & Ends Group -->
                      <div class="group-multiple date">

                        <!-- Employee Salary Starts Group -->
                        <el-form-item
                          class="input-inside-label yellow" :label="$t('starts')" prop="salaryStarts"
                          :class="{'focused': focus.salaryStarts}"
                        >

                          <!-- Employee Salary Starts Group Date Picker -->
                          <el-date-picker
                            v-model="form.salaryStarts"
                            popper-class="date-picker-monthly-dropdown-modal"
                            :popper-append-to-body="false"
                            :picker-options="pickerOptionsStarts"
                            class="date-picker-monthly"
                            type="month"
                            :clearable="false"
                            format="MMM, yyyy"
                            value-format="yyyy-MM-dd"
                            @focus="toggleFocus('salaryStarts')"
                            @blur="toggleFocus('salaryStarts')"
                            @change="toggleFocus('salaryStarts')"
                          />
                          <!-- /Employee Salary Starts Group Date Picker -->

                        </el-form-item>
                        <!-- /Employee Salary Starts Group -->

                        <!-- Employee Salary Ends Group -->
                        <el-form-item
                          class="input-inside-label yellow" :label="$t('ends')" prop="salaryEnds"
                          :class="{'focused': focus.salaryEnds}"
                        >

                          <!-- Employee Salary Ends Group Date Picker-->
                          <el-date-picker
                            v-model="form.salaryEnds"
                            popper-class="date-picker-monthly-dropdown-modal ends"
                            :popper-append-to-body="false"
                            :picker-options="pickerOptionsEnds"
                            class="date-picker-monthly"
                            type="month"
                            :clearable="false"
                            format="MMM, yyyy"
                            value-format="yyyy-MM-dd"
                            @focus="toggleFocus('salaryEnds')"
                            @blur="toggleFocus('salaryEnds')"
                            @change="toggleFocus('salaryEnds')"
                          />
                          <!-- /Employee Salary Ends Group Date Picker-->

                        </el-form-item>
                        <!-- /Employee Salary Ends Group -->
                      </div>
                      <!-- /Employee Salary Starts & Ends Group -->

                    </el-form>
                    <!-- /Form Bottom Group -->

                  </div>
                  <!-- /Form Inner Bottom Group -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Save & Close -->
                <div class="my-4 my-md-0">

                  <!-- Divider -->
                  <ib-divider block class="my-4" />
                  <!-- /Divider -->

                  <el-row :gutter="10" class="d-flex">
                    <el-col :span="12">
                      <!-- Close -->
                      <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closedDialog">
                        {{ $t('close') }}
                      </ib-button>
                      <!-- /Close -->
                    </el-col>

                    <el-col :span="12">
                      <ib-button
                        class="w-100 text-uppercase"
                        size="lg"
                        :disabled="!isAddEnabled"
                        :loading="loading"
                        @click="saveStaffAndPayroll"
                      >
                        {{ form.id === 0 ? $t('add') : $t('save') }}
                      </ib-button>
                    </el-col>
                  </el-row>
                </div>
                <!-- /Save & Close -->

              </el-col>
              <!-- /Left Side -->

              <!-- Center -->
              <el-col :md="12" :xl="7">
                <template v-if="!$store.state.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length">
                  <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                    <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                    <p class="add-dialog-folder-text font-outfit-regular">
                      {{ $t('createdItemsWillAppearHere') }}
                    </p>
                  </div>
                </template>

                <!-- Employees Added -->
                <div ref="cardHolder" class="costs-added">

                  <!-- Employees Added List -->
                  <div
                    v-for="staffAndPayroll in $store.state.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls"
                    :key="staffAndPayroll.id"
                  >
                    <set-up-and-projections-card
                      class="mb-2"
                      :entity="staffAndPayroll"
                      :name="staffAndPayroll.name"
                      :date="staffPayrollDate(staffAndPayroll)"
                      :amount="getFormattedAmount(staffAndPayroll.average)"
                      :frequency="$t('pages.businessGuide.monthlyAverage')"
                      :type="staffAndPayroll.type"
                      view="list"
                      @edit="onEditStaffAndPayroll"
                      @copy="onCopyStaffAndPayroll"
                      @delete="onDeleteStaffAndPayroll"
                    />
                  </div>
                  <!-- /Employees Added List -->
                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </div>
                <!-- /Employees Added -->

              </el-col>
              <!-- /Center -->

              <!-- Right Side -->
              <el-col :xl="10">
                <!-- Info Guide -->
                <info-guide-add-dialog
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.staffAndPayroll.addDialog.guide.title')"
                  :text="$t('pages.businessGuide.staffAndPayroll.addDialog.guide.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Ai Buddy -->
                <ai-buddy
                  type="staff-and-payroll"
                  class="ai-buddy-margin-bottom d-none d-xl-block"
                  @suggestion="form.name = $event"
                />
                <!-- Ai Buddy -->
              </el-col>
              <!-- /Right Side -->
            </el-row>
          </div>
        </div>
        <!-- /Inner Wrapper -->
      </ib-dialog-container>
      <!-- /Content Wrapper -->

    </ib-dialog>
  </div>
  <!-- /Dialog Add Staff And Payroll -->

</template>

<script>
import 'cropperjs/dist/cropper.css'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCalculations from '@/mixins/calculations'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'

import { mapActions, mapGetters } from 'vuex'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'

export default {
  name: 'DialogAddStaff',

  components: {
    AiBuddy,
    InfoGuideAddDialog,
    EraseIconButton,
    AddDialogHeader,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCurrencies,
    MixinCalculations,
    MixinDialog,
    MixinCloseMenu,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles
  ],

  props: {
    dialogAddStaffVisible: {
      type: Boolean,
      default: false
    },
    staffAndPayrollAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      num: 0,
      loading: false,
      removeLoadingExamples: false,
      groups: [
        {
          id: 1,
          selected: false,
          label: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.individual'),
          value: '1',
          imgPath: require('@/assets/img/icons/management-team-icon.svg'),
          options: [
            {
              label: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.fullTime'),
              diffValue: 'fti',
              desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit.',
              value: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.fullTimeIndividual')
            },
            {
              label: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.contractor'),
              diffValue: 'ci',
              desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit.',
              value: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.contractorIndividual')
            }
          ]
        },
        {
          id: 2,
          selected: false,
          label: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.group'),
          value: '2',
          imgPath: require('@/assets/img/icons/staff-group-icon.svg'),
          options: [
            {
              label: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.fullTime'),
              desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit.',
              diffValue: 'ftg',
              value: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.fullTimeGroup')
            },
            {
              label: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.contractor'),
              desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit.',
              diffValue: 'cg',
              value: this.$t('pages.businessGuide.staffAndPayroll.addDialog.fields.contractorGroup')
            }
          ]
        }
      ],
      completed: false,
      dialogVisible: false,
      guideVisible: false,
      expandedDialogGuide: true,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        type: '',
        name: '',
        howMany: '',
        isGroup: false,
        salary: null,
        salaryIntervalType: 'Monthly',
        payAdjustmentPrefix: '+',
        payAdjustmentAmount: null,
        isPayPercentage: false,
        payAdjustmentIntervalType: 'Monthly',
        salaryStarts: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('YYYY-MM-DD'),
        salaryEnds: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        createdAt: null
      },
      focus: {
        type: false,
        name: false,
        howMany: false,
        salary: true,
        salaryIntervalType: false,
        payAdjustmentPrefix: false,
        payAdjustmentAmount: true,
        isPayPercentage: false,
        payAdjustmentIntervalType: true,
        salaryStarts: false,
        salaryEnds: false
      },
      formTop: null,
      ruleFormTop: {
        name: {
          value: '',
          focus: false
        }
      },
      signSwitch: false,
      signValue: 'el-icon-plus',
      paymentSwitch: false
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isAddEnabled () {
      let enabled = this.form.type && this.form.name && !!parseInt(this.form.salary) && this.form.salaryIntervalType && this.form.salaryStarts && this.form.salaryEnds

      if (this.form.type === 'ftg' || this.form.type === 'cg') {
        enabled = enabled && !!this.form.howMany
      }

      return enabled
    },

    pickerOptionsStarts () {
      const maxOccurDate = this.form.salaryEnds ? this.$moment(this.form.salaryEnds).format('x') : this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
      const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

      return {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      }
    },

    pickerOptionsEnds () {
      const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
      const minOccurDate = this.form.salaryStarts ? this.$moment(this.form.salaryStarts).add(1, 'M').format('x') : this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

      return {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      }
    },

    isDialogOpen () {
      return this.dialogAddStaffVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getStaffAndPayrolls')
    }
  },

  mounted () {
    this.checkInputLabels()
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeStaffAndPayroll',
      'deleteIdeaExamples'
    ]),

    staffPayrollDate (staffPayroll) {
      return `${this.getFormattedDate(staffPayroll.salaryStarts)} - ${this.getFormattedDate(staffPayroll.salaryEnds)}`
    },

    toggleValue () {
      this.signSwitch = !this.signSwitch

      if (this.signSwitch) {
        this.form.payAdjustmentPrefix = '+'
        this.signValue = 'el-icon-plus'
      } else {
        this.form.payAdjustmentPrefix = '-'
        this.signValue = 'el-icon-minus'
        this.form.payAdjustmentAmount = this.form.payAdjustmentAmount && this.form.payAdjustmentAmount > this.form.salary ? this.form.salary : this.form.payAdjustmentAmount
      }
    },

    togglePayment () {
      this.form.isPayPercentage = !this.form.isPayPercentage
    },

    onChangeType () {
      this.toggleFocus('type')
      this.checkIsGroup()
      const formType = this.form.type
      this.resetForm()
      this.form.type = formType
      this.checkInputLabels()
    },

    selectGroup (group) {
      this.form.type = ''
      this.groups.forEach(function (g) {
        g.selected = false
      })
      group.selected = !group.selected
      this.form.type = group.label
      this.$refs.groupSelect.focus()
      this.focus.type = true
    },

    focusDate (input) {
      this.focus[input] = true
    },

    toggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]
      if (this.form[input] !== null && this.form[input] !== '') this.focus[input] = true
    },

    formatTooltip (val) {
      return val / 100
    },

    closeDialog () {
      this.$emit('close-dialog-add-staff')
    },

    closedDialog () {
      this.resetForm()
      this.closeDialog()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addStaffPayrollGuide')
      if (this.staffAndPayrollAction) {
        this.form = Object.assign({}, this.staffAndPayrollAction)
        this.form.ideaId = this.$store.state.idea.id
        this.signValue = this.form.payAdjustmentPrefix === '-' ? 'el-icon-minus' : 'el-icon-plus'
        this.signSwitch = this.form.payAdjustmentPrefix === '+'
      }

      if (this.form.payAdjustmentAmount === 0) {
        this.form.payAdjustmentAmount = null
      }

      this.checkInputLabels()
    },

    saveStaffAndPayroll () {
      this.loading = true
      if (this.form.id === 0) {
        this.addStaffAndPayroll()
      } else {
        this.updateStaffAndPayroll()
      }
    },

    addStaffAndPayroll () {
      this.checkIsGroup()

      this.$http.post('story-mode/set-up/staff-and-payroll/staff-and-payroll', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addStaffAndPayroll', response.data.payload.staffAndPayroll)
            this.resetForm()
          }
          this.$emit('on-update-staff-and-payroll-view')
        }).finally(() => {
          this.loading = false
          this.resetForm()
          this.scrollToTop()
        })
    },

    updateStaffAndPayroll () {
      this.checkIsGroup()

      this.$http.put(`story-mode/set-up/staff-and-payroll/staff-and-payroll/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateStaffAndPayroll', response.data.payload.staffAndPayroll)
            this.resetForm()
          }
          this.$emit('on-update-staff-and-payroll-view')
        }).finally(() => {
          this.loading = false
          this.resetForm()
          this.scrollToTop()
        })
    },

    onDeleteStaffAndPayroll (staffAndPayroll) {
      this.deleteStoryModeStaffAndPayroll(staffAndPayroll.id)
        .then((response) => {
          this.$emit('on-update-staff-and-payroll-view')
        })
    },

    onCopyStaffAndPayroll (staffAndPayroll) {
      this.copyStaffAndPayrollToForm(staffAndPayroll)
      this.form.id = 0
    },

    copyStaffAndPayrollToForm (staffAndPayroll) {
      this.resetForm()
      this.form = Object.assign({}, staffAndPayroll)
      this.form.ideaId = this.$store.state.idea.id
      this.signValue = this.form.payAdjustmentPrefix === '-' ? 'el-icon-minus' : 'el-icon-plus'
      this.signSwitch = this.form.payAdjustmentPrefix === '+'
      this.checkInputLabels()
      this.checkIsGroup()
      this.scrollToTop()
    },

    onEditStaffAndPayroll (staffAndPayroll) {
      this.copyStaffAndPayrollToForm(staffAndPayroll)
    },

    checkIsGroup () {
      this.form.isGroup = this.form.type === 'ftg' || this.form.type === 'cg'
      this.form.howMany = this.form.isGroup ? (this.form.howMany === 1 ? '' : this.form.howMany) : 1
    },

    checkInputLength (input) {
      this.focus[input] = this.form[input]
    },

    checkInputLabels () {
      this.checkInputLength('type')
      this.checkInputLength('name')
      this.checkInputLength('howMany')
      this.checkInputLength('salary')
      this.checkInputLength('salaryIntervalType')
      this.checkInputLength('payAdjustmentPrefix')
      this.checkInputLength('payAdjustmentAmount')
      this.checkInputLength('isPayPercentage')
      this.checkInputLength('payAdjustmentIntervalType')
      this.checkInputLength('salaryStarts')
      this.checkInputLength('salaryEnds')
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        type: '',
        name: this.form.name,
        howMany: '',
        isGroup: false,
        salary: null,
        salaryIntervalType: 'Monthly',
        payAdjustmentPrefix: '+',
        payAdjustmentAmount: null,
        isPayPercentage: false,
        payAdjustmentIntervalType: 'Monthly',
        salaryStarts: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('YYYY-MM-DD'),
        salaryEnds: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        createdAt: null
      }
      this.focus = {
        type: false,
        name: false,
        howMany: false,
        salary: true,
        salaryIntervalType: false,
        payAdjustmentPrefix: false,
        payAdjustmentAmount: false,
        isPayPercentage: false,
        payAdjustmentIntervalType: false,
        salaryStarts: false,
        salaryEnds: false
      }
      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getStaffAndPayrolls'].length === 0) {
        this.guideVisible = false
      }
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    changeSalaryIntervalType () {
      if (this.form.salaryIntervalType === 'Quarterly' && this.form.payAdjustmentIntervalType === 'Monthly') {
        this.form.payAdjustmentIntervalType = 'Quarterly'
      }

      if (this.form.salaryIntervalType === 'Annually' && (this.form.payAdjustmentIntervalType === 'Monthly' || this.form.payAdjustmentIntervalType === 'Quarterly')) {
        this.form.payAdjustmentIntervalType = 'Annually'
      }

      this.toggleFocus('salaryIntervalType')
    },

    changePayAdjustmentIntervalType () {
      if (this.form.payAdjustmentIntervalType === 'Quarterly' && this.form.salaryIntervalType === 'Annually') {
        this.form.salaryIntervalType = 'Quarterly'
      }

      if (this.form.payAdjustmentIntervalType === 'Monthly' && (this.form.salaryIntervalType === 'Annually' || this.form.salaryIntervalType === 'Quarterly')) {
        this.form.salaryIntervalType = 'Monthly'
      }

      this.toggleFocus('payAdjustmentIntervalType')
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('staff-and-payrolls')
        this.$emit('on-update-staff-and-payroll-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
